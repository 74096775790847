<template>
  <div>
    <!-- <div class="split overridesplitleft left">
      <img
        src="@/assets/images/logo/sc-logo.png"
        alt="asd"
        style="width:400px;height:250px;"
      >
    </div> -->
    <div class="d-flex flex-column justify-content-center split overridesplitright right">
      <div
        class="screensize"
        style="margin:auto;"
      >
        <div class="d-flex col-12 mb-2">
          <span style="font-size: clamp(28px,5vw,36px); color:black;"><strong>{{ $t ("Update your password") }}</strong></span>
        </div>
        <div class="d-flex col-12 mb-3">
          <span style="font-size:clamp(16px,5vw,24px); color:#4F4F4F;"><strong>{{ $t ("Enter your email") }}</strong></span>
        </div>

        <validation-observer ref="validateEmployee">
          <div class="col-12 mb-4">
            <validation-provider
              #default="{ errors }"
              :name="$t('Email')"
              rules="required|email"
            >

              <!-- <input
                id="email"
                v-model="email"
                type="text"
                name="email"
                class="form-control form-control-lg bg-white"
                style="width: 100%;"
                autocomplete="off"
                placeholder="Email"
                @keypress="checkEmail"
              > -->
              <b-form-input
                v-model="email"
                :placeholder="$t('Email')"
                size="lg"
                class="saj-form-text"
                rows="8"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </div>

          <div class="d-flex col-12 mb-3 no-gutters">

            <!-- <router-link
            class="col-12"
            :to="{
              name:
                'auth-password'}"
          >
            <button
              type="submit"
              class="saj-button btn btn-primary"
              style="width: 100%;"
              @click="apiSendEmail()"
            >SUBMIT</button>
          </router-link> -->
            <button
              type="submit"
              class="saj-button btn btn-primary"
              style="width: 100%;"
              @click="apiSendEmail()"
            >{{ $t ("Submit") }}</button>
          </div>
          <div class="d-flex col-12 mb-3">
            <router-link
              :to="{ name: 'login' }"
              class=""
              style="color:#000;"
            ><i
              class="fas fa-long-arrow-alt-left"
              style="color:#000;"
            />&nbsp;&nbsp;{{ $t ("Back") }}</router-link>
          </div>
        </validation-observer></div>
    </div>

  </div>

</template>
<script>

</script>

<style scoped>
    .split {
      height: 100%;
      width:50%;
      position: fixed;
      z-index: 1;
      top: 0;
      overflow-x: hidden;
      padding-top: 20px;
    }

    .left {
      /* background: url('~@/assets/images/background/login.png'); */
      background-repeat: no-repeat;
      background-size: auto 100%;
      /* background-image: url('~@/assets/images/background/login.png');
      height: 100%; */
      background-position: center;
    }
    .right {
      right: 0;
      background-color: white;
    }

    .centered {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 100px;
      text-align: center;
    }

    .centered img {
      width: 150px;
      border-radius: 50%;
    }

    .code{
      -webkit-appearance: none!important;
      -moz-appearance: textfield!important;
      border-radius: 6px 6px;
      border-width: 2px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      font-size: 34px;
      font-weight: lighter;
      height: 80px;
      margin-left: 4%;
      min-width: 20px;
      max-width: 52px;
      text-align: center;
      -o-text-overflow: clip;
      text-overflow: clip;
    }

    .screensize{
      width: 46%;
    }

    /* Split screen */
    @media screen and (max-width: 1100px) {
        .overridesplitright{
          width: 100% !important;
        }

        .overridesplitleft{
          display: none !important;
        }
    }
    /* End Split screen */
    @media screen and (max-width: 2000px) {
        .screensize{
          width: 46%;
        }

        .code{
          font-size:18px;
          height: 72px;
          margin-left: 3%;
          width: 10px;
        }
    }
    @media screen and (max-width: 1700px) {
      .screensize{
        width: 56%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1400px) {
      .screensize{
        width: 66%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1200px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1100px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 800px) {
      .screensize{
        width: 56%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 500px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:12px;
        height: 62px;
        margin-left: 2%;
        width: 10px;
      }
    }
    @media screen and (max-width: 400px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:12px;
        height: 62px;
        margin-left: 2%;
        width: 10px;
      }
    }
    @media screen and (max-width: 300px) {
      .screensize{
        width: 86%;
      }

      .code{
        font-size:10px;
        height: 52px;
        margin-left: 2%;
        width: 10px;
      }
    }
</style>

<script>
import {
  BFormInput,
} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from "@/component/saj-toastification.vue"
export default {
  components:{
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    },
  data() {
    return {
      required,
      email: '',
      buttonDisabled: true,
    }
  },
  methods: {
    checkEmail(){
      if (this.email === null || this.email === ''){
        this.buttonDisabled = true
      } else {
        this.buttonDisabled = false
      }
    },
    apiSendEmail() {
      this.$refs.validateEmployee.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('email', this.email)

          this.$axios.post(`${this.$baseUrl}/password/forgot`, data).then(response => {

             const newEmail = response.data.data
              // console.log('email: ', newEmail)
              this.$router.push({ name: 'auth-password', params: { getEmail: newEmail} })

          }).catch(() =>{
            this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Please sign up, your email does not exist')}.`,
                icon: "AlertCircleIcon",
                iconColor: 'white',
                iconBg: '#e80202',
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          })
        }
      })
    },
  },
}

</script>
